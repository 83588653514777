import { client } from "@/api/axios";
import DynamicTable from "@/components/DynamicTable";
import { message, Tabs } from "antd";
import type { TabsProps } from "antd";
import React from "react";

const onChange = (key: string) => {
  console.log(key);
};

const payment_status: { [key: string]: string } = {
  PAID: "已支付",
};

const refund_status: { [key: string]: string } = {
  approving: "申请退款",
  agree: "已退款",
  reject: "已拒绝",
};

const columns = [
  {
    title: "ID",
    dataIndex: ["id"],
  },
  {
    title: "类型",
    dataIndex: ["product_order"],
    render: (product_order: any) => (
      <span>{product_order ? "发帖" : "接单"}</span>
    ),
  },
  {
    title: "付款状态",
    dataIndex: "status",
    render: (status: string) => <span>{payment_status[status]}</span>,
  },
  {
    title: "退款状态",
    dataIndex: "refund_status",
    render: (status: string) => <span>{refund_status[status]}</span>,
  },
  {
    title: "发布工作",
    dataIndex: ["product_order", "product", "title"],
    render: (object_type: string) => (
      <span>{object_type === "post" ? "帖子" : "订单"}</span>
    ),
  },
  {
    title: "接单",
    dataIndex: ["promise_order", "products", "description"],
  },
  {
    title: "付款",
    dataIndex: ["promise_order", "price"],
    render: (price: number) => <span>{price}元</span>,
  },
  {
    title: "退款",
    dataIndex: ["promise_order", "refund"],
    render: (price: number) => <span>{price}元</span>,
  },
  {
    title: "用户",
    dataIndex: ["promise_order", "users", "nickname"],
  },
];

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "退款申请",
    children: (
      <div key="1">
        <DynamicTable
          columns={[...columns]}
          key="1"
          dataSourceUrl="/payment_records/refund/approving"
          createUrl="/payment_records"
          updateUrl="/payment_records"
          deleteUrl="/payment_records"
          title="退款申请"
          readonly
          customeActions={[
            {
              title: "同意退款",
              onClick: async (record: any) => {
                if (record.promise_order) {
                  await promise_refund(record, "agree");
                } else {
                  await product_refund(record, "agree");
                }
              },
            },
            {
              title: "拒绝退款",
              onClick: async (record: any) => {
                if (record.promise_order) {
                  await promise_refund(record, "refuse");
                } else {
                  await product_refund(record, "refuse");
                }
              },
            },
          ]}
        />
      </div>
    ),
  },
  {
    key: "2",
    label: "已同意",
    children: (
      <div key="2">
        <DynamicTable
          key="2"
          columns={[...columns]}
          dataSourceUrl="/payment_records/refund/agree"
          createUrl="/payment_records"
          updateUrl="/payment_records"
          deleteUrl="/payment_records"
          title="付款记录"
          readonly
        />
      </div>
    ),
  },
  {
    key: "3",
    label: "已拒绝",
    children: (
      <DynamicTable
        key="2"
        columns={[...columns]}
        dataSourceUrl="/payment_records/refund/reject"
        createUrl="/payment_records"
        updateUrl="/payment_records"
        deleteUrl="/payment_records"
        title="付款记录"
        readonly
      />
    ),
  },
];

const App: React.FC = () => (
  <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
);

export default App;

async function promise_refund(record: any, action: string) {
  const response = await client.post(`/wechat/refund/${action}`, {
    product_id: record.promise_order.product_id,
    mode: "promise",
    user_id: record.user_id,
  });
  if (response.data?.message) {
    message.info(response.data?.message);
    setTimeout(() => {
      location.reload();
    }, 2000);
  }
  return response;
}

async function product_refund(record: any, action: string) {
  const response = await client.post(`/wechat/refund/${action}`, {
    product_id: record.product_order.product_id,
    mode: "product",
    user_id: record.user_id,
  });
  if (response.data?.message) {
    message.info(response.data?.message);
    setTimeout(() => {
      location.reload();
    }, 2000);
  }
  return response;
}
